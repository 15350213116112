import { createAction, props } from '@ngrx/store';

import { ChannelGuestReviews } from '../../models';

export const loadRequest = createAction(
  '[Channel Guest Reviews] Load Request',
  props<{ channelId: number; propertyId: number }>(),
);

export const loadSuccess = createAction(
  '[Channel Guest Reviews] Load Success',
  props<{ items: ChannelGuestReviews[] }>(),
);

export const loadFailure = createAction(
  '[Channel Guest Reviews] Load Failure',
  props<{ error: any }>(),
);

export const replyRequest = createAction(
  '[Channel Guest Reviews] Reply Request',
  props<{
    request: {
      reply_text: string;
      review_id: string;
      channel_id: number;
      property_id: number;
    };
  }>(),
);

export const replySuccess = createAction(
  '[Channel Guest Reviews] Reply Success',
  props<{ review_id: string; can_reply: boolean }>(),
);

export const replyFailure = createAction(
  '[Channel Guest Reviews] Reply Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Channel Guest Reviews] Reset State');
