import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ChannelGuestReviewsService {
  constructor(private http: HttpClient) {}

  load(channelId: number, propertyId: number) {
    return this.http.get(
      `channelmanager/guests_reviews/${channelId}/${propertyId}`,
    );
  }

  reply(data: {
    reply_text: string;
    review_id: string;
    channel_id: number;
    property_id: number;
  }) {
    return this.http.post(
      `channelmanager/guests_reviews_reply/${data.channel_id}/${data.property_id}`,
      data,
    );
  }
}
