import { createAction, props } from '@ngrx/store';

import { ChannelScore } from '../../models';

export const loadRequest = createAction(
  '[Channel Score] Load Request',
  props<{ channelId: number; propertyIds: number[] }>(),
);

export const loadSuccess = createAction(
  '[Channel Score] Load Success',
  props<{ items: ChannelScore[] }>(),
);

export const loadFailure = createAction(
  '[Channel Score] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Channel Score] Reset State');
