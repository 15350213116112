import { Injectable } from '@angular/core';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { get, upperFirst } from 'lodash';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ChannelGuestReviewsService } from '../../services/channel-guest-reviews.service';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class ChannelGuestReviewsStoreEffects {
  constructor(
    private dataService: ChannelGuestReviewsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
    private translateService: TranslateService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ type, ...payload }) =>
        this.dataService.load(payload.channelId, payload.propertyId).pipe(
          map((response: IResponseSuccess) =>
            fromActions.loadSuccess({
              items: get(response, ['data']),
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  replay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.replyRequest),
      switchMap(({ type, ...payload }) =>
        this.dataService.reply(payload.request).pipe(
          map((response: IResponseSuccess) => {
            this.notifications.push({
              title: upperFirst(this.translateService.instant('done')),
              content: upperFirst(
                this.translateService.instant('replied_review'),
              ),
              type: 'success',
            });

            const { review_id } = payload.request;
            return fromActions.replySuccess({ review_id, can_reply: false });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.replyFailure(error));
          }),
        ),
      ),
    ),
  );
}
