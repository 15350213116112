import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ChannelScoreStoreEffects } from './effects';
import { channelScoreReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('channelScore', channelScoreReducer),
    EffectsModule.forFeature([ChannelScoreStoreEffects]),
  ],
})
export class ChannelScoreStoreModule {}
